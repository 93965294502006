import React, {useEffect, useMemo, useRef, useState} from 'react';
import {CollectionProducts, interpreter, other} from "./SiteData";
import noSizesImage from "./media/images/noSizesImg.png";
import {Footer} from "./Footer";
import bandeiras from "./media/images/bandeiras.png";
import sewImage from "./media/sew.png"
import {useHistory} from "react-router-dom";
import Slider from "react-slick";
import whatsWhite from "./media/whatsappWhite.png"
import bike from "./media/images/bike.png"
import {useAxios} from "./helpers/Api";
import {CountCart, globalMaskReal, maskRealToView} from "./helpers/Functions";
import {BsCartPlus, BsFlower2} from "react-icons/bs";
import {GiCottonFlower, GiDandelionFlower, GiFireFlower} from "react-icons/gi";
import {
    FacebookIcon, FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {InstagramList} from "./components/InstagramList";
import {InstagramVideoList} from "./components/InstagramVideoList";
import MostSaledComponent from "./pages/HomePage/Components/MostSaledComponent";
import branch from "./media/branch.png";
import {CartComponent} from "./components/CartComponent";
import {toast} from "react-toastify";
import useLocalStorage from "./helpers/storage";
import {useCart} from "./helpers/useCart";
import {BiSolidCartAdd} from "react-icons/bi";
import {FaCaretRight, FaCheckCircle, FaCreditCard, FaInstagram, FaWhatsapp} from "react-icons/fa";
import {TbNeedle} from "react-icons/tb";
import {MdDiscount} from "react-icons/md";
import Drawer from "react-modern-drawer";
import {TableDress} from "./components/TableSizes";


const ProductPage = (props) => {
    const [product, setProduct] = useState(null);
    const [productWhatsData, setProductWhatsData] = useState({variation: props.match.params.id})
    const history = useHistory();
    const nav1Ref = useRef();
    const nav2Ref = useRef();
    const [nav1, setNav1] = useState()
    const [nav2, setNav2] = useState()
    const api = useAxios();
    const [openCart, setOpenCart] = useState(false);
    const cartHook = useCart();
    const [sizes, setSizes] = useState([]);
    const [noSizeModal, setNoSizeModal] = useState(false);
    const getSizes = async () => {
        try {
            let res = await api.get('/all-sizes/list');
            setSizes(res.data.object);
        } catch (e) {
            console.log(e);
        }
    };

    const getProduct = async () => {

        try {
            let res = await api.get('/product/variation/'+props.match.params.id);
            setProduct(res.data.object);
        } catch (e) {
            console.log(e);
        }
    }

    const changeVariation = (item) => {
        history.push('/produto/'+ item.flora_product_variation_id + window.location.search)
    }

    const onCartAddClick = async (cart) => {

        if (!product.size) {
            toast.info('Escolha um tamanho');
            return;
        }

        let available = false;

        product?.product_variation_available_sizes.forEach((item) => {
            if(item.flora_size_id === productWhatsData.size.flora_size_id) {
                available = true
            }
        });
        if(available) {
            await cartHook.addToCart(product);
            !cart && setOpenCart(true);
        } else {
            setNoSizeModal(true)
        }
    }

    function getAvailableSize (flora_size_id) {
        let found = false;
        if(!flora_size_id) {
            found = true; //como fica inicialmente
        }
        product?.product_variation_available_sizes.forEach((item) => {
            if(item.flora_size_id === flora_size_id) {
                found =  true;
            }
        });

        return found;
    }

    const sendToWhatsapp = () => {

        let message =`
        Olá, gostaria de encomendar um vestido.
        %0A
        ${product.name}
        %0A
        Tamanho - ${productWhatsData.size.name}
        %0A
        Modelo - ${product.product.name}
        %0A
        %0A
        Obrigado(a)!
        `
        window.open('https://wa.me/5567991797649?text='+message.replaceAll(',',''), '_blank').focus();

        setNoSizeModal(false);
    }

    const onChange = (label,value) => {
        setProductWhatsData({...productWhatsData, [label]: value});
        setProduct({...product, [label]: value})
    }

    useEffect(() => {
        getProduct();
        getSizes();
    },[props.match.params.id])

    useEffect(() => {
        if(product) {
            const element = document.getElementById('bread');
            element.scrollIntoView({ behavior: 'smooth' });
            setNav1(nav1Ref.current)
            setNav2(nav2Ref.current)
        }
    },[props.match.params.id, product?.flora_product_variation_id])

    if(!product) return ;
    return (
        <>
            <CartComponent open={openCart} handleOpen={(item) => setOpenCart(item)}/>
            <Drawer size={320} width={310} direction={"right"} open={noSizeModal} onClose={() => setNoSizeModal(false)}>
                <div className={'p-4 d-flex flex-column justify-content-center align-items-center'}>
                    <h6 className={' text-center mb-4 font-weight-bold'}
                        style={{letterSpacing: 5}}>PARECE QUE NÃO TEMOS ESTE TAMANHO <strong
                        style={{color: '#3b1b46'}}> A PRONTA ENTREGA </strong></h6>

                    <img src={noSizesImage} style={{maxHeight: 200}}/>
                    <div className={'my-2 mt-4'}>
                        <h5>Vamos encomendar?</h5>
                        <span style={{color: '#636363'}}>Apesar de não termos o produto a pronta entrega, você pode encomendar ele comigo no <strong
                            style={{color: '#4dc247'}}>WhatsApp!</strong> a única diferença é que pedimos pelo menos 5
                            dias para confeccionar antes do envio.</span>
                    </div>

                    <div className="btn btn-dark w-100 mt-3" style={{backgroundColor: '#4dc247', borderColor:  '#4dc247'}}>
                        <div>
                            <a onClick={() => sendToWhatsapp()}
                               className="add-to-cart mr-0" style={{letterSpacing: '3px'}}>
                                ENCOMENDAR
                            </a>
                        </div>
                    </div>
                </div>
            </Drawer>
            <div className={'pt-3 pt-lg-0 pt-xl-5'}>
                <section className="breadcrumb-area" id={'bread'}>
                    <div className="container-fluid custom-container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="bc-inner mt-4">
                                    <p className={'font-weight-light'}><a href="#"
                                                                          onClick={() => history.push('/' + window.location.search)}>Home
                                        |</a> {product.product.name}</p>
                                </div>
                            </div>
                            {/* /.col-xl-12 */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                {/*=========================*/}
                {/*=        Shop area          =*/}
                {/*=========================*/}
                <section className="shop-area single-product">
                    <div className="container-fluid custom-container">
                        <div className="row">
                            {/* /.col-xl-3 */}
                            <div className="order-1 order-md-2 col-md-12">
                                <div className="row">
                                    <div className="col-lg-6 col-xl-6">
                                        {/* Product View Slider */}
                                        <div className="">
                                            <div className="">
                                                <Slider
                                                    asNavFor={nav2}
                                                    adaptiveHeight={true}
                                                    ref={slider => (nav1Ref.current = slider)}
                                                >
                                                    {product.product_images.map((item, index) => <div key={index}>
                                                        <img src={item.image_s3_url} style={{
                                                            boxShadow: ' 0px 20px 20px 0px rgba(80, 100, 126, 0.4)',
                                                            objectFit: 'contain',
                                                            width: 'auto'
                                                        }} alt="Thumb"/>
                                                    </div>)}
                                                </Slider>
                                            </div>
                                            <div className="hideDots">
                                                <Slider
                                                    asNavFor={nav1}
                                                    ref={slider => (nav2Ref.current = slider)}
                                                    slidesToShow={4}
                                                    responsive={[
                                                        {
                                                            breakpoint: 1024,
                                                            settings: {
                                                                slidesToShow: 3,
                                                                slidesToScroll: 1,
                                                            }
                                                        },
                                                        {
                                                            breakpoint: 600,
                                                            settings: {
                                                                slidesToShow: 2,
                                                                slidesToScroll: 1
                                                            }
                                                        }
                                                    ]}
                                                    swipeToSlide={true}
                                                    focusOnSelect={true}
                                                >
                                                    {product.product_images.map((item, index) => <div key={index}>
                                                        <img style={{height: 160, objectFit: 'cover'}}
                                                             src={item.image_s3_url} alt="thumb"/>
                                                    </div>)}
                                                </Slider>
                                            </div>
                                        </div>
                                        {/* /.quickview-slider */}
                                    </div>
                                    {/* /.col-xl-6 */}
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="product-details">
                                            <h5 className="pro-title"><a
                                                href="#">{product.product.name} - {product.name}</a></h5>


                                            <div className={'d-flex flex-column'} style={{
                                                borderBottom: '1px solid gainsboro',
                                                marginBottom: 20,
                                                paddingBottom: 20
                                            }}>
                                                {product.product.discount > 0 &&
                                                    <div className={'d-flex align-items-center'}><span
                                                        className="price_from pb-0 "
                                                        style={{fontSize: 15}}>R$ {maskRealToView(Number(product.product.price))} </span>
                                                        <span style={{
                                                            color: '#673ab7',
                                                            fontWeight: 500,
                                                            paddingBottom: 0
                                                        }}>{product.product.discount}% OFF</span></div>
                                                }

                                                <span className=" pb-2">
                                                    <strong className={'text-success'}
                                                            style={{fontSize: 20}}> R$ {maskRealToView(product.product.price - (((product.product.discount + 5) / 100) * product.product.price))} </strong> no PIX <span
                                                    className={'text-success'}>(5% OFF) </span>
                                                </span>
                                                <span>
                                                    ou no cartão em até 10x  de <strong> R$ {maskRealToView(Number((product.product.price - ((product.product.discount / 100) * product.product.price)) / 10))}</strong> sem juros
                                                </span>


                                            </div>
                                            {sizes?.length > 0 &&
                                                <div className="mb-3 align-items-center">
                                                    <span style={{fontWeight: 500}}>Escolha o tamanho </span>
                                                    <div className="d-flex flex-wrap">
                                                        {sizes?.map((item, index) =>
                                                            <div style={{position: "relative"}}
                                                                 onClick={() => onChange('size', item)} key={index}
                                                                 className={`mb-1 button-tag ${productWhatsData?.size?.flora_size_id === item.flora_size_id && 'active'}`}>
                                                                <div className={'mask'} style={{display: 'none'}}>
                                                                    <FaCheckCircle style={{color: '#673ab7'}}/>
                                                                </div>
                                                                {item.name}
                                                                {getAvailableSize(item.flora_size_id) ? <div style={{
                                                                    position: "absolute",
                                                                    width: 20,
                                                                    height: 20,
                                                                    background: '#ebdcf0',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    borderRadius: '50%',
                                                                    right: -8,
                                                                    top: -8
                                                                }}><i style={{fontSize: 12}} className={'bi bi-truck'}/>
                                                                </div> : ''}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>}

                                            {product.product?.product_variations &&
                                                <div className="align-items-center mt-2 mb-3">
                                                    <span style={{fontWeight: 500}} className={'pb-0 pr-0 mr-1'}>Modelos   </span>
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        {product.product.product_variations?.map((item, index) =>
                                                            <div onClick={() => changeVariation(item)} key={index}
                                                                 className={`cloth-types mb-1 round ${product?.flora_product_variation_id == item.flora_product_variation_id && 'active'}`}>
                                                                <div className={'mask'} style={{display: 'none'}}>
                                                                    <FaCheckCircle style={{color: '#673ab7'}}/>
                                                                </div>

                                                                <img style={{objectFit: "cover"}}
                                                                     src={item.product_images[0].image_s3_url}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>}


                                            {product.product?.product_bonus_main?.length > 0 &&
                                                <div className="align-items-center mt-2 mb-3">
                                                    <div className={'d-flex align-items-center mb-1'}>
                                                        <span style={{fontWeight: 500}} className={'pb-0 pr-0 mr-1'}> Brindes acompanhados </span>
                                                        <GiCottonFlower style={{color: '#9c27b0'}}/>

                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        {product.product.product_bonus_main?.map((item, index) =>
                                                            <div key={index} className={`cloth-types round active`}>
                                                                <div className={'mask'} style={{display: 'none'}}>
                                                                    <FaCheckCircle style={{color: '#673ab7'}}/>
                                                                </div>
                                                                <img src={item.bonus_product.image_s3_url}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>}

                                            <div className='d-flex flex-wrap  mt-5 '>
                                                <div className="btn btn-dark w-100" style={{backgroundColor: 'black'}}>
                                                    {/*PRODUCT INCREASE BUTTON START*/}
                                                    <div>
                                                        <a onClick={() => onCartAddClick()}
                                                           className="add-to-cart mr-0" style={{letterSpacing: '3px'}}>
                                                            {(getAvailableSize(productWhatsData?.size?.flora_size_id)) ? 'COMPRAR' : 'ENCOMENDAR'}
                                                        </a>
                                                    </div>
                                                </div>
                                                <a style={{border: '1px solid #4d295b'}}
                                                   onClick={() => onCartAddClick(true)}
                                                   className="btn w-100 mt-3 btn-outline-light d-flex justify-content-center align-items-center">
                                                    Adicionar
                                                    <BiSolidCartAdd size={26}/>
                                                </a>
                                            </div>
                                            {/* <div>
                                                <div>
                                                    <div className={'d-flex align-items-center mt-1'}>
                                                        <img style={{width: 15, height: 15}} src={bike}/>

                                                        <span style={{fontSize: 12, textTransform: 'capitalize'}} className={'p-0'}>Entrega Grátis</span>
                                                    </div>
                                                </div>
                                            </div>*/}

                                            <div className={"mt-4  d-flex justify-content-center"}>
                                                <img src={bandeiras}/>
                                            </div>


                                            <span style={{
                                                fontWeight: 500,
                                                marginTop: 40,
                                                marginBottom: 0
                                            }}>Sobre a peça </span> <br/>
                                            <span
                                                className={'text-dark font-weight-bold'}>{product.product.name} - {product.name}</span>
                                            <p className={'mt-1 pt-1'}>{product.product.description}</p>


                                            <div className='d-flex align-items-center alertProduct p-3'>

                                                <p><img src={sewImage}
                                                        style={{
                                                            height: 50,
                                                            width: 50,
                                                            marginRight: 10
                                                        }}/> Nossos produtos são feitos com materiais de alta qualidade,
                                                    escolhidos para assegurar durabilidade e um toque refinado.
                                                    Cada peça é elaborada para oferecer um padrão superior de conforto e
                                                    elegância.
                                                    <a
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#4D295B',
                                                            marginLeft: 4,
                                                            fontSize: 14
                                                        }}
                                                        href={window.innerWidth < 768 ? 'https://wa.me/5567991797649' : 'https://wa.me/5567991797649'}>
                                                        entre em contato
                                                    </a>
                                                </p>

                                            </div>

                                            <div className={'d-flex align-items-center mt-4 mb-3'}>
                                                <span className={'mb-0 pb-0'}>Gostou? Ajude compartilhando </span>
                                            </div>
                                            <div className={'d-flex flex-wrap'}>
                                                <FacebookShareButton url={window.location.href}
                                                                     className="Demo__some-network__share-button mx-2">
                                                    <FacebookIcon size={32} round/>
                                                </FacebookShareButton>

                                                <WhatsappShareButton
                                                    url={window.location.href}
                                                    title={product.product.name}
                                                    separator=":: "
                                                    className="Demo__some-network__share-button mx-2"
                                                >
                                                    <WhatsappIcon size={32} round/>
                                                </WhatsappShareButton>

                                                <FacebookMessengerShareButton
                                                    url={window.location.href}
                                                    appId="521270401588372"
                                                    className="Demo__some-network__share-button mx-2"
                                                >
                                                    <FacebookMessengerIcon size={32} round/>
                                                </FacebookMessengerShareButton>
                                                <div onClick={() => window.open(other.instagram, '_blank')} style={{
                                                    height: 35,
                                                    background: 'linear-gradient(45deg, #d300c5, #fb5c04)',
                                                    width: 35,
                                                    borderRadius: "50%"
                                                }} className={'d-flex mx-2 align-items-center justify-content-center'}>
                                                    <FaInstagram className={'text-white'} size={25}/>
                                                </div>
                                            </div>


                                        </div>
                                        {/* /.product-details */}
                                    </div>
                                    {/* /.col-xl-6 */}
                                    <div className="col-xl-12">
                                        <div className="product-des-tab pt-4">
                                            <ul className="nav nav-tabs " role="tablist">
                                                <li className="nav-item">
                                                    <a onClick={() => window.gtag && window.gtag("event", "table_range_click")}
                                                       className="nav-link active show" id="profile-tab" data-toggle="tab"
                                                       href="#profile" role="tab" aria-controls="profile"
                                                       aria-selected="false">Medidas</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="frete-tab" data-toggle="tab"
                                                       href="#frete" role="tab" aria-controls="frete"
                                                       aria-selected="true">Frete</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="home-tab" data-toggle="tab"
                                                       href="#home" role="tab" aria-controls="home"
                                                       aria-selected="true">Cuidados</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link " id="video-tab" data-toggle="tab"
                                                       href="#video" role="tab" aria-controls="home"
                                                       aria-selected="true">Como é feito </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade  " id="home" role="tabpanel"
                                                     aria-labelledby="home-tab">
                                                    <div className="prod-bottom-tab-sin description">
                                                        <h5>Alguns cuidados para tomar com o vestido após a compra</h5>
                                                        <ul>
                                                            <li> Separe o vestido de outras roupas durante a lavagem
                                                                para evitar o desbotamento ou manchas.
                                                            </li>
                                                            <li> Lave o vestido com água fria e não deixe o vestido de molho  para evitar
                                                                encolhimento e danos às fibras.
                                                            </li>
                                                            <li> Use sabão suave e detergente sem alvejante para evitar
                                                                danificar as fibras do tecido.
                                                            </li>
                                                            <li> Não deixe o vestido de molho por muito tempo.</li>
                                                            <li> Armazene o vestido em um local fresco e seco, evitando
                                                                a exposição direta à luz solar ou a ambientes úmidos.
                                                            </li>
                                                            <li> Certifique-se de que o vestido esteja completamente
                                                                seco antes de guardá-lo no armário.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="tab-pane fade  " id="frete" role="tabpanel"
                                                     aria-labelledby="frete-tab">
                                                    <div className="prod-bottom-tab-sin description">
                                                        <h5>Oferecemos as seguintes opções de envio:</h5>
                                                        <ul>
                                                            <li>
                                                                PAC: Entrega de 6 a 7 dias úteis, por <strong>R$ {globalMaskReal(product.freight_price)}</strong>.
                                                            </li>
                                                            <li>
                                                                SEDEX: Entrega rápida de 2 a 3 dias úteis, por <strong>R$
                                                                {globalMaskReal(product.freight_sedex_price)}</strong>.
                                                            </li>
                                                            <li>
                                                                <strong>FRETE GRÁTIS</strong> para compras acima de R$ 399.
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="tab-pane fade " id="video" role="tabpanel"
                                                     aria-labelledby="video-tab">

                                                    <div className={'d-flex flex-column mb-5 prod-bottom-tab-sin'}>
                                                        <h5> Veja como é o processo</h5>
                                                        <p>Cada vestido é feito a mão, pensado desde a modelagem ate a
                                                            costura, feitos detalhadamamente com todo carinho e amor
                                                            para sua princesa, veja algumas confecções.</p>
                                                    </div>

                                                    <InstagramVideoList/>
                                                </div>
                                                <div className="tab-pane fade show active" id="profile" role="tabpanel"
                                                     aria-labelledby="profile-tab">
                                                    <div className={'d-flex flex-column mb-5 prod-bottom-tab-sin'}>
                                                        <h5> Tabela de Medidas</h5>
                                                        <div className={'mb-4'}>
                                                            <p> Para auxiliar na escolha, disponibilizamos uma tabela de medidas detalhada. Recomendamos que você siga as instruções abaixo para medir a sua princesa:
                                                            </p>
                                                            <ul style={{color: '#464646'}} className={'mb-3'}>
                                                                <li><strong>Busto</strong> - Meça ao redor da parte mais cheia do busto, mantendo a fita métrica confortável, mas não apertada.</li>
                                                                <li><strong>Cintura</strong> -  Meça ao redor da cintura natural da criança, que normalmente é a parte mais estreita do tronco.</li>
                                                                <li><strong>Quadril</strong> - Meça ao redor da parte mais larga do quadril.</li>
                                                                <li><strong>Comprimento do Vestido</strong> - Meça do ombro até a altura desejada do vestido.</li>
                                                            </ul>
<p>                                                         Utilize estas medidas para comparar com nossa tabela de tamanhos e escolher o ajuste mais apropriado. Lembre-se de que pode haver uma pequena variação nas medidas devido às características manuais do processo de confecção.

                                                            Se ainda tiver dúvidas sobre qual tamanho escolher, estamos disponíveis para ajudar, é só entrar em contato!
</p>

                                                        </div>
                                                        {TableDress()}
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.col-xl-9 */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.shop-area */}
                <section className="main-product padding-top-120 pb-0">
                    <div className="container container-two">
                        <div className={'d-flex w-100 justify-content-center'}>
                            <img src={branch} style={{height: 50}}/>
                        </div>
                        <div className="section-heading mt-2">
                            <h3 className={'pb-0'} style={{color: 'black'}}>Você também pode <span>gostar</span></h3>
                        </div>
                        {/* /.section-heading*/}
                        <MostSaledComponent hideLabel={true} padding0={true}/>

                        {/* Row End */}
                    </div>
                    {/* Container  */}
                </section>

                <div className={'row m-0 pb-5'}>

                    <div className={'col-12 col-lg-4 d-flex align-items-center p-3 justify-content-center mb-3 mb-lg-0'}
                         style={{backgroundColor: '#fafafa'}}>
                        <TbNeedle className={'mr-1'} style={{color: '#3b1b46'}}/>
                        <span className={'text-uppercase'} style={{color: '#3b1b46'}}>Confeccionados a mão</span>
                    </div>

                    <div className={'col-12 col-lg-4 d-flex align-items-center p-3 justify-content-center mb-3 mb-lg-0'}
                         style={{backgroundColor: '#fafafa'}}>
                        <FaCreditCard className={'mr-1'} style={{color: '#3b1b46'}}/>
                        <span className={'text-uppercase'} style={{color: '#3b1b46'}}>Em até 10x sem juros</span>
                    </div>

                    <div className={'col-12 col-lg-4 d-flex align-items-center p-3 justify-content-center mb-3 mb-lg-0'}
                         style={{backgroundColor: '#fafafa'}}>
                        <MdDiscount className={'mr-1'} style={{color: '#3b1b46'}}/>
                        <span className={'text-uppercase'} style={{color: '#3b1b46'}}>5% OFF no PIX</span>
                    </div>

                </div>
                {/* main-product */}
                {/*=========================*/}
                {/*=   Subscribe area      =*/}
                {/*=========================*/}
                {/* subscribe-area */}
                {/*=========================*/}
                {/*=   Footer area      =*/}
                {/*=========================*/}
                {/* footer-widget-area */}
                {/* Back to top
	============================================= */}
            </div>
        </>
    )
};

ProductPage.propTypes = {};

export default ProductPage;
